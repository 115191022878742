import moment from 'moment';
import * as d3 from 'd3';

export const duration = (s, accuracy = 'H') => {
  const input = Math.round(Number(s));
  const days = Math.floor(input / (60 * 60 * 24));
  let rest = input % (60 * 60 * 24);
  const hours = Math.floor(rest / (60 * 60));
  rest = rest % 3600;
  const min = Math.floor(rest / 60);
  rest = rest % 60;
  // return `${days > 0 ? `${days}d` : ''} ${hours > 0 ? `${hours}h` : ''} ${min > 0 ? `${min}m` : ''} ${rest > 0 ? `${rest}s` : ''}`.trim();
  let dur = `${days > 0 ? `${days}D` : ''} ${hours > 0 ? `${hours}H` : ''}`.trim();
  if (!dur.length || accuracy === 'm') {
    dur = (dur + ` ${min > 0 ? ` ${min}m` : ''}`).trim();
  }
  if (!dur.length || accuracy === 's') {
    dur = (dur + ` ${rest > 0 ? ` ${rest}s` : ''}`).trim();
  }
  if (!dur.length) {
    dur = '0s';
  }
  return dur;
};

export const hmsDuration = (s, accuracy = 'H') => {
  if(s === null || s === undefined || isNaN(s) || s === Infinity || s === -Infinity) {
    return '-'
  }
  const input = Math.round(Number(s));
  const days = Math.floor(input / (60 * 60 * 24));
  let rest = input % (60 * 60 * 24);
  const hours = Math.floor(rest / (60 * 60));
  rest = rest % 3600;
  const min = Math.floor(rest / 60);
  const seconds = Math.floor(rest % 60);

  let dur = `${seconds} sec`;
  let sp = num => num >9 ? num : `0${num}`;
  if(min > 0) {
    dur = `${sp(min)}m ${sp(seconds)}s`;
  }
  if(hours > 0) {
    // dur = `${sp(hours)}:${sp(min)}:${sp(seconds)}`;
    dur = `${sp(hours)}h ${sp(min)}m`;
  }
  if(days > 0) {
    // dur = `${days}d ${hours}h ${min}m`;
    dur = `${days}d ${hours}h`;
  }
  if(days >= 1) {
    dur = `${intFormatter(Math.floor(input / (60 * 60)))}h`
  }
  return dur;
};

export const sinceThen = timestamp => {
  if (!timestamp) {
    return '--';
  }
  return duration(moment().diff(moment(timestamp), 's'));
};
export const responseTimeFormatter = d => {
  return d3.format(d > 1000 * 60 ? '.5' : '.3')((d || 0) / 1000) + 's';
};


export const uniquesFormatter = num => {
  if (isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  if (num < 1024) {
    return d3.format(',.0f')(num);
  } else {
    return d3.format('.3s')(num);
  }
}
export const uniquesFormatter0 = num => {
  if (isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  if (num < 1024) {
    return num;
  } else {
    return d3.format('.3s')(num);
  }
}

export const intFormatter = (num, abbr) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  if (abbr) {
    if (num < 1024) {
      return d3.format(',.0f')(num);
    } else {
      return d3.format('.3s')(num);
    }
  } else {
    return d3.format(',')(num)
  }
}
export const pctFormatter = (num, digit = 1) => {
  if (num === Infinity || num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  if (digit !== 1) {
    return d3.format(`,.${digit || 1}%`)(num);
  }
  return d3.format(`,.1%`)(num);
}

export const pct2Formatter = (num) => {
  return pctFormatter(num, 2);
}
export const pct0Formatter = (num) => {
  if (num === Infinity || num === null || num === undefined || isNaN(num)) {
    return '--'
  }
  else if (!num) {
    return '0';
  }
  return d3.format(`,.0%`)(num);
}

export const periodFormat = (d) => {
  if(typeof d === 'string') {
    return {
      last7days: 'Last 7 Days',
      last30days: 'Last 30 Days',
      last60days: 'Last 60 Days',
      last90days: 'Last 90 Days',
      last180days: 'Last 180 Days'
    }[d] || d;
  }
  else if (!d.end || d.end === 'Unlimited') {
    return moment(d.start).format('DD MMM, YYYY') + ' - Unlimited';
  } else {
    let start = moment(d.start);
    let end = moment(d.end);
    if (start.year() === end.year()) {
      return start.format('DD MMM') + ' - ' + end.format('DD MMM, YYYY')
    }
    else {
      return start.format('DD MMM, YYYY') + ' - ' + end.format('DD MMM, YYYY')
    }
  }
}
export const getTicks = (domain, count) => {
  var e10 = Math.sqrt(50),
    e5 = Math.sqrt(10),
    e2 = Math.sqrt(2);

  function ticks(start, stop, count) {
    var reverse, i = -1, n, ticks, step;

    stop = +stop;
    start = +start;
    count = +count;
    if (start === stop && count > 0) return [start];
    reverse = stop < start
    if (reverse) {
      n = start;
      start = stop;
      stop = n;
    }
    if ((step = tickIncrement(start, stop, count)) === 0 || !isFinite(step)) return [];

    if (step > 0) {
      start = Math.ceil(start / step);
      stop = Math.floor(stop / step);
      ticks = new Array(n = Math.ceil(stop - start + 1));
      while (++i < n) ticks[i] = (start + i) * step;
    } else {
      start = Math.floor(start * step);
      stop = Math.ceil(stop * step);
      ticks = new Array(n = Math.ceil(start - stop + 1));
      while (++i < n) ticks[i] = (start - i) / step;
    }

    if (reverse) ticks.reverse();

    return ticks;
  }

  function tickIncrement(start, stop, count) {
    var step = (stop - start) / Math.max(0, count),
      power = Math.floor(Math.log(step) / Math.LN10),
      error = step / Math.pow(10, power);
    return power >= 0
      ? (error >= e10 ? 10 : error >= e5 ? 5 : error >= e2 ? 2 : 1) * Math.pow(10, power)
      : -Math.pow(10, -power) / (error >= e10 ? 10 : error >= e5 ? 5 : error >= e2 ? 2 : 1);
  }

  let values = ticks(domain[0], domain[1], count);
  return values;
  // return values.slice(1, count + 1);
}

const excludesKeywords = ['random_ranking', 'rtf_v', 'MAB', 'cbf', 'cbf1', 'cbf_v2', 'cbf_v5', 'vcbf', 'vcbf_v2', 'usr1', 'usr2', 'usr_cbf_v1_1', 'usr_cbf_v1_2', 'usr_cbf_v1_3', 'usr_cbf_v2_1', 'usr_cbf_v2_2', 'usr_cbf_v2_3', 'usr_cbf_v5_1', 'usr_cbf_v5_2', 'usr_cbf_v5_3', 'usr_vcbf_v1_1', 'usr_vcbf_v1_2', 'usr_vcbf_v1_3', 'usr_vcbf_v2_1', 'usr_vcbf_v2_2', 'usr_vcbf_v2_3', 'ccr_cbf_v1_1', 'ccr_cbf_v1_2', 'ccr_cbf_v1_3', 'ccr_cbf_v2_1', 'ccr_cbf_v2_2', 'ccr_cbf_v2_3', 'ccr_cbf_v5_1', 'ccr_cbf_v5_2', 'ccr_cbf_v5_3', 'ccr_vcbf_v1_1', 'ccr_vcbf_v1_2', 'ccr_vcbf_v1_3', 'ccr_vcbf_v2_1', 'ccr_vcbf_v2_2', 'ccr_vcbf_v2_3', 'ccr', 'nmf_mewatch', 'nmf_8world', 'nmf_cna_app', 'nmf_cna', 'nmf_tdy', 'nmf_seithi', 'nmf_berita', 'nmf_melisten', 'nmf_8days', 'trn', 'trn_sns', 'trn_sec', 'trn_ccr', 'trn_m30', 'trn_h03', 'trn_h12', 'trn_h24', 'trn_h36', 'trn_h48', 'trn_media_h03', 'trn_media_h06', 'trn_media_h12', 'trn_media_h24', 'user_cf_knn', 'user_cf_vae', 'user_cf_lgcn', 'user_cf_bpr', 'recent', 'rnd'];
export const filterTags = (tags, type, isAdmin) => {
  if(!tags) {
    return [];
  }
  if(isAdmin) {
    return tags;
  } else {

    return tags.filter(t => t.indexOf('algorithm') < 0 && t.indexOf('ONECMS') < 0 && !excludesKeywords.find(k => t.indexOf(k) >= 0));
  }

  // if(type !== 'abtesting') {
  //   return tags;
  // } else {
  //   return tags.filter(t => {
  //     if(/^backfill/ig.test(t)) {
  //       return false;
  //     }
  //     return ['platform', 'project', 'page'].some(prefix => t.indexOf(prefix) === 0)
  //   })
  // }
}
